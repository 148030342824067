.button
{
    border: unset;
    border-radius: 5px;
    outline: unset;
    padding: 8px;
    cursor: pointer;
    color: inherit;
    position: relative;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.274);
    background: linear-gradient(to bottom, rgb(214, 214, 214), rgb(175, 175, 175));
}

.button .content
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.button .description
{
    bottom: calc(100% + 10px);
    left: 0;
    position: absolute;
    background: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.274);
    z-index: 2;
    pointer-events: none;
    
    opacity: 0;
    will-change: opacity;
    transition: opacity 200ms 300ms;
}

.button:hover .description
{
    opacity: 1;
}

.button::before
{
    content: "";
    position: absolute;
    background: currentColor;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    border-radius: 5px;

    will-change: opacity;
    transition: opacity 200ms;
}

.button:hover::before
{
    opacity: 0.1;
}

.button:active::before
{
    opacity: 0.4;
}

.button.outline
{
    border: solid 2px #033823;
}

.button.simple,
.button.outline
{
    background: transparent;
    box-shadow: unset;
}

.button.dense
{
    padding: 4px;
}

.button.dense .content,
.button.dense .content *
{
    font-size: 1rem;
}