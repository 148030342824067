.input
{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-inner-wrapper
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 0;
}

.input.has-value
{
    padding-top: 1rem;
}

.input .label
{
    color: grey;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: hidden;
    will-change: bottom, font-size;
    transition: 300ms;
    font-size: 0.9rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.input.has-value .label
{
    bottom: 1.8rem;
}

.input input
{
    background: transparent;
    color: white;
    border: solid 2px #073C5E;
    border-radius: 5px;
    outline: unset;
    padding: 8px;
}

.input input.invalid
{
    border-color: red;
}

.input .error
{
    text-align: center;
    color: red;
    font-size: 0.7rem;
}