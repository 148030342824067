.snackbar
{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    will-change: bottom;
    transition: bottom 200ms;
}

.snackbar-message
{
    display: flex;
    padding: 4px 10px;
    background: black;
    color: rgb(146, 146, 146);
    border-radius: 10px;
    font-size: 0.8rem;
    column-gap: 10px;
    align-items: center;
}